/* modular scale em */
$scale-4: 0.4096em;
$scale-3: 0.512em;
$scale-2: 0.64em;
$scale-1: 0.8em;
$scale:		1em;
$scale1:  1.25em;
$scale2:  1.414em;
$scale3:  1.953125em;
$scale4:  2.44140625em;
$scale5:  3.0517578125em;
$scale6:  3.8146972656em;
$scale7:  4.768371582em;
$scale8:  5.9604644775em;
$scale9:  7.4505805969em;
$scale10: 9.3132257462em;
$scale11: 12em;
$scale12: 14.5519152284em;
$scale13: 18.1898940355em;
$scale14: 22.7373675443em;
$scale15: 28.4217094304em;
$scale16: 35.527136788em;
$scale17: 44.408920985em;
$scale18: 55.5111512313em;
$scale19: 69.3889390391em;
$scale20: 86.7361737988em;

$radius: 0.25em;
$mobile: 600px;
$max-width: 1000px;
