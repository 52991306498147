@import 'components/global.scss';

.app {

  padding: $scale2;
  min-height: 100%;
  background-color: $c-bg;

  @media (min-width: $mobile){

    padding-left: $scale7 + $scale1;

  }
}

.apppm {
  padding: 0px 0px 0px 68px;
  min-height: 100%;

}